.Banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 43px;
}

.Banner-container {
    position: relative;
    width: 100%;
    height: 223px;
}

.Banner-img {
    position: absolute;
    border: 0px solid;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    filter: brightness(0.5);
}

.Banner-title {
    position: absolute;
    color: #ffffff;
    left: 26%;
    top: 35%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    margin-top: 0%;
    margin-bottom: 0%;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .Banner {
        margin-bottom: 22px;
    }

    .Banner-title {
        font-size: 24px;
        left: 4%;
        right: 37%;
        top: 23%;
    }

    .Banner-container {
        height: 111px;
    }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
    .Banner {
        margin-bottom: 22px;
    }

    .Banner-title {
        font-size: 36px;
        left: 4%;
        right: 28%;
        top: 25%;
    }

    .Banner-container {
        height: 167px;
    }
}