.container {
    margin: auto 6.945%;
}

.main-accommodation {
    display: flex;
    flex-direction: column;
    color: #FF6060;
    margin-top: 30px;
}

.header-accommodation {
    display: flex;
    justify-content: space-between;
}

.logement-accommodation h1 {
    font-size: 36px;
    font-weight: 500;
    margin-top: 0%;
    margin-bottom: 0%;
}

.city-accommodation h2 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0%;
}

.tags-accommodation {
    display: flex;
    margin-top: 20px;
    margin-bottom: 23px;
}

.tag-accommodation {
    margin-right: 10px;
    background: #FF6060;
    color: white;
    font-size: 14px;
    border-radius: 10px;
}

.tags-accommodation p {
    margin: 0;
    padding: 3px 37px;
}

.fiche-accommodation {
    display: flex;
    flex-direction: column;
    text-align: end;
    justify-content: space-around;
}

.identity-accommodation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.proprio-accommodation {
    font-weight: 500;
    font-size: 19px;
    margin-right: 10px;
    width: 45.23%;
}

.photo-accommodation img {
    border-radius: 100%;
    width: 52px;
    height: 52px;
}


.ratings-accommodation {
    margin-top: 24px;
}

.ratings-accommodation .fa-star path {
    margin-left: 10px;
    fill: grey !important;
}

.ratings-accommodation .fa-star.full path {
    fill: #FF6060 !important;
}

.body-accommodation {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.description-accommodation {
    width: 47.088%;
}

.equipment-accommodation {
    width: 47.088%;
}

.collapse-content-accommodation {
    height: 249px;
}

.collapse-content-accommodation p,
.collapse-content-accommodation ul {
    margin: 0;
    list-style-type: none;
}

.collapse-content-accommodation ul {
    padding: 0;
}

.title-collapse-accommodation {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .container {
        margin: auto 20px;
    }

    .header-accommodation {
        flex-wrap: wrap;
    }

    .fiche-accommodation {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .logement-accommodation h1 {
        font-size: 18px;
    }

    .city-accommodation h2 {
        font-size: 14px;
    }

    .tags-accommodation {
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .tag-accommodation {
        font-size: 10px;
        border-radius: 5px;
    }

    .tag-accommodation p {
        padding: 2px 20px;
    }

    .proprio-accommodation {
        font-size: 12px;
        width: 36.23%;
    }

    .photo-accommodation img {
        width: 32px;
        height: 32px;
    }

    .ratings-accommodation {
        margin-top: 8px;
    }

    .rating-accommodation svg {
        margin-left: 5px;
        width: 15px;
    }

    .body-accommodation {
        flex-wrap: wrap;
    }

    .description-accommodation,
    .equipment-accommodation {
        width: 100%;
    }

    .collapse-container.open .collapse-content-accommodation {
        padding-top: 23px;
        height: fit-content;
    }

    .collapse-content-accommodation ul li,
    .collapse-content-accommodation p {
        font-size: 12px;
    }

    .collapse-header-accommodation {
        height: 30px;
    }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
    .container {
        margin: auto 20px;
    }

    .header-accommodation {
        flex-wrap: wrap;
    }

    .logement-accommodation h1 {
        font-size: 27px;
    }

    .city-accommodation h2 {
        font-size: 16px;
    }

    .tags-accommodation {
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .tag-accommodation {
        font-size: 12px;
        border-radius: 5px;
    }

    .tag-accommodation p {
        padding: 2px 20px;
    }

    .proprio-accommodation {
        font-size: 12px;
        width: 41.23%;
    }

    .photo-accommodation img {
        width: 32px;
        height: 32px;
    }

    .ratings-accommodation {
        margin-top: 8px;
    }

    .rating-accommodation svg {
        margin-left: 5px;
        width: 15px;
    }

    .body-accommodation {
        flex-wrap: wrap;
    }

    .description-accommodation,
    .equipment-accommodation {
        width: 100%;
    }

    .collapse-container.open .collapse-content-accommodation {
        padding-top: 23px;
        height: fit-content;
    }

    .collapse-content-accommodation ul li,
    .collapse-content-accommodation p {
        font-size: 12px;
    }

    .collapse-header-accommodation {
        height: 30px;
    }
}