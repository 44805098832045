.container-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.link-card {
    text-decoration: none;
    color: black;
}

.card {
    display: flex;
    flex-direction: column-reverse;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    border-radius: 10px;
    height: 340px;
    width: 340px;
    margin: auto 10px 50px 10px;
}

.card-title {
    font-size: 18px;
    font-weight: 500;
    width: 80%;
    text-align: start;
    margin: 5px 0px auto 20px;
}

.card-img {
    border-radius: 10px 10px 0px 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.card-top {
    height: 20%;
}

.card-bottom {
    height: 80%;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .card {
        display: flex;
        flex-direction: column-reverse;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        border-radius: 10px;
        width: 335px;
        /* width: 335px; */
        height: 255px;
        /* height: 255px; */
        margin: auto 0px 50px 0px;
    }

    .card-top {
        height: 26%;
    }

    .card-bottom {
        height: 75%;
    }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {}