.customs {
    padding-left: 208.5px;
    padding-right: 208.5px;
}

.collapse-header {
    display: flex;
    height: 47px;
    border-radius: 5px;
    background: #FF6060;
    align-items: center;
    justify-content: space-between;
    padding-left: 19px;
    padding-right: 16px;
    /* margin-bottom: 31px; */
}

.collapse-header h2 {
    font-size: 24px;
    font-weight: 500;
}

.collapse-header svg {
    width: 24px;
    fill: white;
}

.collapse-container.open .vector {
    animation-name: noRotate;
    animation-duration: 0.5s;
    transform: rotate(180deg);
}

.collapse-container:not(.open) .vector {
    animation-name: rotate;
    animation-duration: 0.5s;
    transform: rotate(0deg);
}

.collapse-container.open .collapse-content {
    background: #F6F6F6;
    color: #FF6060;
    margin-bottom: 31px;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 400;
    padding: 27px 27px 19px 18px;
    animation-name: d-flex;
    animation-duration: 0.5s;
    opacity: 1;
    display: flex;
}

.collapse-container:not(.open) .collapse-content {
    animation-name: d-none;
    animation-duration: 0.5s;
    opacity: 0;
    display: none;
}


.collapse-container:not(.open) .collapse-margin {
    margin-bottom: 31px;
}

.collapse-container.open .collapse-noMargin {
    margin-bottom: 0px;
}

@keyframes rotate {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes noRotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

@keyframes d-none {
    from {
        display: flex;
        opacity: 1;
    }

    to {
        display: none;
        opacity: 0;
    }
}

@keyframes d-flex {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: flex;
        opacity: 1;
    }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .collapse-container {
        padding-left: 0;
        padding-right: 0;

    }

    .collapse-header h2 {
        font-size: 13px;
        font-weight: 500;
    }

    .collapse-header svg {
        fill: white;
        width: 13px;
    }

    .collapse-content {
        font-size: 12px;
        font-weight: 400;
    }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
    .collapse-container {
        padding-left: 0;
        padding-right: 0;

    }

    .collapse-header h2 {
        font-size: 18.5px;
        font-weight: 500;
    }

    .collapse-header svg {
        fill: white;
        width: 18.5px;
    }

    .collapse-content {
        font-size: 17.5px;
        font-weight: 400;
    }

}