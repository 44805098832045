.main {
    margin: auto 100px auto 100px;
}


.Home-main {
    background: #F6F6F6;
    border: 0px solid black;
    border-radius: 25px;
    padding-top: 56px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 425px) {
    .main {
        margin: auto 5.34% auto 5.34%;
    }

    .Home-main {
        background: white;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

}

@media screen and (min-width: 426px) and (max-width: 1024px) {
    .main {
        margin: auto 5.34% auto 5.34%;
    }

    .Home-main {
        background: white;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

}